@import "./variable.scss";

$tom-thumb: map_get($base-colors, "tom-thumb");
$gurkha-light: map_get($base-colors, "gurkha-light");
$mirage: map_get($base-colors, "mirage");
$white: map_get($font-colors, "white");
$light_gray: map_get($font-colors, "light-gray");

.navbar-brand {
  color: #000000;

  font-size: 20px;

  display: inline-block;
  justify-content: right;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.menu-drawer {
  .menu-container {
    width: 250px;
    background-color: #000;
    height: 100%;

    .logo {
      width: 100%;

      .navbar-brand {
        align-items: center;
        justify-content: center;
        display: flex;

        img {
          width: 75%;
          height: auto;
        }
      }
    }

    ul>div {
      .menu {
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 20px 15px;
        cursor: pointer;
        text-decoration: none;
        color: $white;
        position: relative;

        &:hover {
          color: $gurkha-light;
        }
      }
    }
  }
}

.header-menu-area {
  display: flex;
  flex-direction: row;
  background-color: black;
  align-items: center;
  justify-content: space-between;

  color: #666666;

  .logo {
    width: 18%;
    color: black;
    margin-left: 50px;

    img {
      width: 100%;
      height: auto;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navbar-brand {
    color: black;
    margin-right: 400px;
  }

  .menu-area {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1vw;
  }

  .menu {
    cursor: pointer;
    text-decoration: none;
    position: relative;

    font-size: 16px;
    text-transform: capitalize;
    color: $light_gray;
    font-family: "Open Sans", sans-serif;
    padding: 0 25px;
    letter-spacing: 1.6px;

    &:hover {
      color: #0cbe80;
      transition-duration: 500ms;
    }
  }

  .active-menu {
    color: #0cbe80;
    border-left: 1px solid #ec5757;
    border-right: 1px solid #ec5757;
    font-weight: bold;
  }

  .collapsible-menu-icon {
    display: none;
  }

  @media only screen and (max-width: 770px) {
    .logo {
      width: 50%;
      display: block;
      margin: 0px;
      margin-left: -5px;

      a {
        padding: 20px 0px;
      }
    }

    .menu {
      cursor: pointer;
      text-decoration: none;
      position: relative;

      font-size: 16px;
      text-transform: capitalize;
      color: #2a303b;
      font-weight: 500;
      padding: 12px;

      &:hover {
        color: #ff8f0f;
        transition-duration: 500ms;
      }
    }

    .collapsible-menu-icon {
      //position: absolute;
      top: 10px;
      right: 0;
    }

    .collapsible-menu-icon {
      display: block;
      margin: 0 10px 0 10px;
      cursor: pointer;
    }

    .collapsed {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.is-expanded {
        overflow: hidden;
        max-height: 500px;
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }

      li {
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
      }
    }

    .layout-container {
      .content-wrap {
        margin-top: -30px;
      }
    }
  }
}

.fixed-header {
  position: fixed;
  width: 100%;
  top: 0%;
  background-color: black;
  border-bottom: 1px solid #b19777;
  z-index: 100;
  margin-top: 0;

  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  @media only screen and (max-width: 570px) {
    padding: 10px;
    width: 95%;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}